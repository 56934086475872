import $ from "jquery";

/**
 * @constructor
 */
class HTTPSender {
    static SETUP_DONE = false;
    static BEARER = null;
    static REQUESTS = [];
    static URL = "https://router.tremol.bg:44330/WebPms/CSMSFuvas/API/";
    static SetBearer(bearer) {
        if (bearer && (HTTPSender.BEARER == null || HTTPSender.BEARER != bearer)) {
            HTTPSender.BEARER = "Bearer " + bearer;
        }
    }

    static AbortAll = function () {
        HTTPSender.REQUESTS.forEach((r) => r.abort());
        HTTPSender.BEARER = null;
    };

    static SendR = function (verb, dataToSend, prefix, queryParams) {
        return HTTPSender.SendRequest(verb, dataToSend, HTTPSender.URL, prefix, queryParams)
    }

    static SendRequest = function (verb, dataToSend, url, prefix, queryParams) {
        //console.info("HTTP: " + prefix);
        //console.info(dataToSend)
        var queryString = "";
        if (queryParams) {
            queryString = "?" + $.param(queryParams);
        }
        if (!prefix) {
            prefix = "";
        }
        var dataO = {
            // timeout: 5000,
            type: verb,
            async: true,
            url: url + prefix + queryString,
            crossDomain: true,
            // dataType: "json",
            contentType: "json",
            //xhrFields: { withCredentials: true },
            //processData: false,
            beforeSend: function (/** @type {JQueryXHR} */ xhr) {
                if (HTTPSender.BEARER) {
                    xhr.setRequestHeader("Authorization", HTTPSender.BEARER);
                }
                xhr.setRequestHeader("Accept", "application/json");
                // xhr.setRequestHeader("Access-Control-Request-Headers", "access-control-allow-origin, allow-control-allow-credentials, Origin, X-Requested-With, Content-Type, Accept")
                xhr.setRequestHeader('Access-Control-Allow-Origin', url);
                // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                //xhr.setRequestHeader('Content-Type', 'application/octet-stream');
                //xhr.setRequestHeader('Content-Type', contentType);
                xhr.setRequestHeader("Content-Type", "application/json");
                //xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                HTTPSender.REQUESTS.push(xhr);
            },
            complete: function (xhr) {
                HTTPSender.REQUESTS.splice(HTTPSender.REQUESTS.indexOf(xhr), 1);
                //console.info(JSON.stringify(xhr.responseJSON));
            },
        };
        //dataO.contentType = "json";
        // if (responseType) {
        //   dataO.xhr = function () {
        //     var xhr = new XMLHttpRequest();
        //     xhr.responseType = responseType;
        //     return xhr;
        //   };
        // }
        dataO.data = dataToSend;
        // console.log(dataO)
        return $.ajax(dataO);
    };

    static SendMultiplePostR = function (arrayOfDataToSend, prefix, queryParams, done, fail) {
        $.when(...arrayOfDataToSend.map(d => HTTPSender.SendPOSTreq(JSON.stringify(d), prefix, queryParams)))
            .then(done, fail);
    }
    // static SendPOSTreq = function(dataToSend, urlserver, prefix, contentType) {
    //     return HTTPSender.SendR(true, dataToSend, urlserver, prefix, contentType, null);
    //}

    static SendPOSTreq = function (dataToSend, prefix, queryParams) {
        return HTTPSender.SendR("POST", dataToSend, prefix, queryParams);
    };

    // static SendGETreq = function(urlserver, prefix, responseType) {
    //     return HTTPSender.SendR(false, null, urlserver, prefix, responseType,  null);
    // }

    static SendGETreq = function (prefix, queryParams) {
        return HTTPSender.SendR("GET", null, prefix, queryParams);
    };
}

if (!HTTPSender.SETUP_DONE) {
    $.ajaxSetup({
        statusCode: {
            401: function () {
                location.href = "./login?error=Необходимо е да влезете в системата!";
            }
        }
    });
    HTTPSender.SETUP_DONE = true;
}

export default HTTPSender;

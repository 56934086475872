<template>
  <div
    v-show="highlighter.visible"
    class="ma-0 pa-0"
    :style="
      'position: absolute; width: ' +
      w +
      'px;height: ' +
      h +
      'px;top: ' +
      y +
      'px;left: ' +
      x +
      'px;background: ' +
      transparent_color +
      '; border: ' +
      border +
      'px solid ' +
      border_color +
      '; border-radius: ' +
      border * 2 +
      'px;'
    "
  ></div>
</template>

<script>
export default {
  data() {
    return {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      border: 3,
      default_border_color: "gray",
      border_color: "gray",
      transparent_color: "rgba(255, 255, 255, 0)",
    };
  },
  methods: {
    show: function (/** @type {HTMLElement} */ element) {
      this.stop();
      this.$nextTick(() => {
        var rect = element.getClientRects()[0];
        //var rect = element.getBoundingClientRect();
        this.highlighter.visible = true;
        this.x = rect.left - this.border;
        this.y = rect.top - this.border;
        this.w = rect.right - rect.left + this.border * 2;
        this.h = rect.bottom - rect.top + this.border * 2;
        var reps = 6;
        this.highlighter.interval = setInterval(() => {
          reps--;
          this.border_color =
            reps % 2 ? this.default_border_color : this.transparent_color;
          if (reps <= 0) {
            this.stop();
          }
        }, 500);
      });
    },
    stop() {
      if (this.highlighter.interval != null) {
        clearInterval(this.highlighter.interval);
      }
      this.highlighter.visible = false;
    },
  },
  mounted() {
    this.highlighter.highlight = this.show;
    this.highlighter.stop = this.stop;
    this.default_border_color = this.Colors.IconColor;
    this.border_color = this.Colors.IconColor;
  },
};
</script>

<style scoped>
.roundedcorners {
  border-radius: 5px;
}

div >>> v-snack__content {
  width: unset;
}
</style>
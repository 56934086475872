import Vue from "vue";
import VueRouter from "vue-router";
import Login from "./components/LoginPage/LoginView";

import MainContent from "./components/MainView.vue";

Vue.use(VueRouter);
//const base_video_url = "https://a.tremol.bg/video/";

var router = new VueRouter({
  mode: "history", //"hash",
  base: "/", // Основната папка на домейна REAL SERVER
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes: [
    {
      title: "Tremol EV demo",
      name: "login", path: "/login",
      component: Login,
      props: true
    },
    {
      title: "Tremol EV demo",
      name: "main",
      path: "/MainContent",
      component: MainContent,
      meta: {
        title: "",
        require_login: true,
      },
    },
    { name: "catchAll", path: "/*", redirect: "/login" },
    { name: "catchAll2", path: "*", redirect: "/login" },
    { name: "catchAll3", path: "/*/", redirect: "/login" },
    { name: "empty", path: "/", redirect: "/login" },
  ],
});
export default router;
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.highlighter.visible),expression:"highlighter.visible"}],staticClass:"ma-0 pa-0",style:('position: absolute; width: ' +
    _vm.w +
    'px;height: ' +
    _vm.h +
    'px;top: ' +
    _vm.y +
    'px;left: ' +
    _vm.x +
    'px;background: ' +
    _vm.transparent_color +
    '; border: ' +
    _vm.border +
    'px solid ' +
    _vm.border_color +
    '; border-radius: ' +
    _vm.border * 2 +
    'px;')})
}
var staticRenderFns = []

export { render, staticRenderFns }
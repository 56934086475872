<template>
  <loading :background-color="isInDarkMode ? '#000' : '#FFF'" :color="isInDarkMode ? '#FFF' : '#000'" loader="bars" :active.sync="dialog.modal_loader" :opacity="0.88" style="z-index: 99998;">
    <span slot="after">
      <br />
      {{ dialog.options && dialog.options.message ? dialog.options.message : msg }}
    </span>
  </loading>

  <!-- <div id="loader-wrapper">
    <v-card class="loader-card-back">
    <v-row class="loader-card">
      <v-col class="subtitle-1 text-center white--text" cols="12">
        <span class>{{ msg }}</span>
      </v-col>
      <v-col cols="12">
        <v-progress-linear v-if="linear" style="background-color: rgb(18, 77, 104);" indeterminate color="light-blue" height="10" striped></v-progress-linear>
        <v-row v-else justify="center">
          <v-progress-circular :size="70" :width="7" color="light-blue" indeterminate></v-progress-circular>
        </v-row>
      </v-col>
    </v-row>
    </v-card>
  </div>-->
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  props: {},
  data() {
    return {
      msg: {
        type: String,
        default: "Зареждане на информация...",
      },
    };
  },
  computed: {},
  methods: {},
  created() {
    if (this.dialog.options && this.dialog.options.message) {
      this.msg = this.dialog.options.message;
    }
  },
  mounted() {
    
  },
};
</script>

<style>
.vld-icon {
  text-align: center;
}
/* #loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.loader-card-back {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
}
.loader-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
} */
</style>
class global_dialog {
    constructor() {
        this.dialog_types = {
            receipt_view: "receipt-view",
            filter_view: "filter-view",
            column_selector: "column-selector",
            picker: "picker-view",
            date_picker: "date-picker",
            yes_no: "yes-no",
            invoice: "invoice",
            loader: "loader",
            google_map: "google-map",
            device_edit: "device-edit",
            captcha: "captcha",
        }
        this.modal_receipt_view = false;
        this.modal_filter = false;
        this.modal_column_selector = false;
        this.modal_picker_view = false;
        this.modal_date_picker = false;
        this.modal_invoice = false;
        this.modal_yes_no = false;
        this.modal_loader = false;
        this.modal_google_map = false;
        this.modal_device_edit = false;
        this.modal_captcha = false;
        this.last_dialog = "default";
        this.data = null;
        this.options = null;
        this.result = null;
        this.callbackz = {};
        this.close = async function (noCallBack, onlyLoader) {
            this.modal_loader = false;
            if (!onlyLoader) {
                this.modal_picker_view = false;
                this.modal_date_picker = false;
                this.modal_receipt_view = false;
                this.modal_filter = false;
                this.modal_column_selector = false;
                this.modal_yes_no = false;
                this.modal_invoice = false;
                this.modal_google_map = false;
                this.modal_device_edit = false;
                this.modal_captcha = false;
            }
            const res = this.result;
            const d = this.last_dialog;
            new Promise(resolve => { //TODO await?
                if (this.callbackz[d] && !noCallBack) {
                    this.callbackz[d](res);
                    this.callbackz[d] = null;
                }
                resolve()
            })
        }

        /**
         * 
         * @param {dialog_types} dialog_name
         */
        this.show = async function (dialog_name, data, options, cb) {
            return new Promise((resolve) => {
                this.last_dialog = dialog_name;
                this.data = data;
                this.options = options;
                this.callbackz[this.last_dialog] = cb;
                if (dialog_name.toLowerCase() == this.dialog_types.receipt_view) {
                    this.modal_receipt_view = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.filter_view) {
                    this.modal_filter = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.column_selector) {
                    this.modal_column_selector = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.picker) {
                    this.modal_picker_view = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.date_picker) {
                    this.modal_date_picker = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.yes_no) {
                    this.modal_yes_no = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.invoice) {
                    this.modal_invoice = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.loader) {
                    this.modal_loader = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.google_map) {
                    this.modal_google_map = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.device_edit) {
                    this.modal_device_edit = true;
                } else if (dialog_name.toLowerCase() == this.dialog_types.captcha) {
                    this.modal_captcha = true;
                }
                //})
                resolve();
            });
        }
    }
}
export default global_dialog;
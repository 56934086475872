var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticStyle:{"background-color":"white"},attrs:{"app":"","height":80}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('img',{attrs:{"width":60,"height":"auto","src":require("../assets/TRG03_22.jpg")}}),_c('v-select',{staticClass:"centered-input",staticStyle:{"width":"120px","max-width":"120px"},attrs:{"disabled":_vm.isTerminalProcessing,"items":_vm.Devices,"dense":"","single-line":"","hide-details":""},model:{value:(_vm.Device),callback:function ($$v) {_vm.Device=$$v},expression:"Device"}}),_c('v-row',{attrs:{"self-align":"center","justify":"center"}},_vm._l((_vm.cars),function(car,idx){return _c('img',{key:idx,staticClass:"mx-3",staticStyle:{"cursor":"pointer"},style:(car != null && car.state != _vm.CarState.Gone
              ? 'visibility: hidden;'
              : ''),attrs:{"height":80,"src":require("../assets/car-key2.png")},on:{"click":function($event){return car.arrive()}}})}),0)],1)],1),_c('v-main',{attrs:{"app":""}},[_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"justify-content":"space-around"}},_vm._l((_vm.cars),function(car,idx){return _c('v-row',{key:idx,attrs:{"justify":"center","align":"center"}},[_c('label',{staticStyle:{"position":"relative","z-index":"2222","left":"397px","top":"-10px","font-weight":"bold"}},[_vm._v(_vm._s(idx + 1))]),(car)?_c('label',{staticStyle:{"position":"relative","z-index":"2222","left":"335px","top":"60px","font-weight":"bold","font-size":"14px","color":"green"}},[_vm._v(_vm._s(car.chargingAmount .roundTo(2) .toFixed(2) .toString() .padStart(5, "0") + " лв."))]):_vm._e(),_c('img',{class:car &&
              (car.state == _vm.CarState.Charging ||
                car.state == _vm.CarState.StoppedCharging ||
                car.state == _vm.CarState.Preauthing ||
                car.state == _vm.CarState.Paying ||
                car.state == _vm.CarState.Stopped)
                ? 'station-cropped'
                : '',staticStyle:{"position":"relative","left":"255px"},attrs:{"height":100,"src":require("../assets/pro-135-reverse.png")}}),_c('CarView',{ref:'car' + idx,refInFor:true,staticStyle:{"position":"relative","left":"-118px","top":"0px"},attrs:{"plugNum":idx + 1},on:{"click":function($event){return _vm.carClick(car)},"carArrived":function($event){return _vm.carArrived(car)},"carStartCharging":function($event){return _vm.carStartCharging(car)},"carStoppedPreauthOrPay":function($event){return _vm.carStoppedPreauthOrPay(car)},"carLeave":function($event){return _vm.carLeave(car)},"amountChanged":function($event){return _vm.amountChanged(car)}}})],1)}),1)],1)],1),_c('v-bottom-navigation',{attrs:{"app":"","height":80}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-select',{staticClass:"centered-input mr-4",staticStyle:{"width":"140px","max-width":"140px"},attrs:{"disabled":_vm.isTerminalProcessing,"items":_vm.ChargingChoices,"dense":"","label":"Сума за преавторизация","hide-details":""},model:{value:(_vm.CurrentMaxChargingAmount),callback:function ($$v) {_vm.CurrentMaxChargingAmount=_vm._n($$v)},expression:"CurrentMaxChargingAmount"}}),_vm._l((_vm.cars),function(car,idx){return _c('v-btn',{key:idx,staticClass:"mx-4 px-0 rounded-btn elevation-5",class:_vm.getButtonClass(car),style:(_vm.getButtonStyle(car)),attrs:{"height":60,"width":10},on:{"click":function($event){$event.preventDefault();return _vm.buttonClick(car)}}},[_c('label',{staticStyle:{"font-size":"20px","cursor":"inherit"}},[_vm._v(_vm._s(idx + 1)+" ")])])})],2),_c('label',{staticStyle:{"position":"absolute","bottom":"10px","right":"10px"}},[_vm._v(_vm._s(_vm.version)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div ref="refLandingBackground" :style="'height: ' + getHeight" style="display: inline-block; position: absolute; left: 0; right: 0; z-index: 0; overflow: hidden">
    <img :class="reversed ? 'myImg' : ''" :src="BG" width="100%" height="100%" style="z-index: -1; position: absolute; width: 100%" :style="'height: ' + getHeight + ';opacity: ' + opacityprop" />
    <img :class="reversed ? 'myImg' : ''" :src="BG_1" width="100%" height="auto" style="position: absolute; margin-left: 0; margin-right: 0; left: 0; right: 0; top: 0; bottom: auto" />
    <img :class="reversed ? 'myImg' : ''" :src="BG_2" width="100%" height="auto" />
    <img :class="reversed ? 'myImg' : ''" :src="BG_3" width="100%" height="auto" />
    <img :class="reversed ? 'myImg' : ''" :src="BG_4" width="100%" height="auto" />
    <img :class="reversed ? 'myImg' : ''" :src="BG_5" width="100%" height="auto" style="position: absolute; margin-left: 0; margin-right: 0; left: 0; right: 0; top: auto; bottom: 0" />
  </div>
</template>
<script>
export default {
  props: {
    reversed: {
      type: Boolean,
      default: false,
    },
    noautoheight: {
      type: Boolean,
      default: false,
    },
    opacityprop: {
      type: String,
      default: "1",
    }
  },
  data() {
    return {
      heightBG: "100%",
      BG: require("../../assets/background/Sky_Background.jpg"),
      BG_1: require("../../assets/background/Sky_Curve_white.svg"),
      BG_2: require("../../assets/background/Connection_WAVE_white.svg"),
      BG_3: require("../../assets/background/Sky_Curve_blue.svg"),
      BG_4: require("../../assets/background/Connection_WAVE_blue.svg"),
      BG_5: require("../../assets/background/Sky_Curve_blue_down.svg"),
    };
  },
  computed: {
    getHeight() {
      return this.heightBG;
    },
  },
  methods: {
    recalcBackgroundHeight() {
      setTimeout(() => {
        if (this.noautoheight) return;
        if (this.$refs.refLandingBackground) {
          this.heightBG = this.$refs.refLandingBackground.parentElement.clientHeight + "px;";
        }
      }, 500);
    },
  },
  mounted() {
    if (this.reversed) {
      this.BG_1 = require("../../assets/background/Sky_Curve_blue_down.svg");
      this.BG_2 = require("../../assets/background/Connection_WAVE_blue.svg");
      this.BG_4 = require("../../assets/background/Connection_WAVE_white.svg");
      this.BG_5 = require("../../assets/background/Sky_Curve_white.svg");
    }
    this.$nextTick(() => {
      this.recalcBackgroundHeight();
    });
  },
  created() {
    window.addEventListener("resize", this.recalcBackgroundHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.recalcBackgroundHeight);
  },
};
</script>

<style scoped>
img.myImg {
  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
</style>
<template>
  <v-container
    fill-height
    class="ma-0 pa-0"
    fluid
    align="center"
    justify="center"
  >
    <!-- <t-background style="min-height: 1900px; "></t-background> -->

    <t-background
      noautoheight
      style="
        position: absolute;
        min-height: 5500px;
        top: -3600px;
        width: 1920px;
      "
    ></t-background>
    <v-row align="center" justify="center" class="my-0 py-0 px-2">
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="5"
        xl="5"
        class="my-0 py-0"
        align="center"
      >
        <v-img
          contain
          width="auto"
          :max-width="500"
          height="auto"
          style="cursor: pointer"
          class="ma-0 pa-0"
          @click="openTremolSite()"
          :src="require('../../assets/tremollogo.png')"
        ></v-img>

        <v-card class="elevation-24 mt-4" shaped>
          <!-- <v-toolbar v-if="!isOnMobile" dense :color="Colors.IconColor" flat>
            <v-spacer />
            <v-toolbar-title class="white--text">Добре дошли!</v-toolbar-title>
            <v-spacer />
          </v-toolbar>-->
          <v-card-text>
            <v-form ref="loginForm" v-model="valid" lazy-validation>
              <v-row align="center" justify="center" class="ma-2 mb-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-text-field
                    ref="refUser"
                    v-model="username"
                    name="username"
                    placeholder=" "
                    autocomplete="username"
                    label="Потребител"
                    prepend-icon="mdi-account"
                    @keyup.enter="login()"
                  />
                </v-col>
                <v-col v-if="!forgotenPasswordMode" cols="12" class="ma-0 pa-0">
                  <v-text-field
                    v-model="password"
                    id="password"
                    placeholder=" "
                    prepend-icon="mdi-lock"
                    name="password"
                    autocomplete="current-password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Парола"
                    :rules="global_rules.password"
                    @click:append="show1 = !show1"
                    @keydown="isCapsOn"
                    @keyup.enter="login()"
                  >
                    <CapsTooltip v-if="capsOn" slot="append-outer" />
                  </v-text-field>
                </v-col>
                <!-- <v-col v-if="forgotenPasswordMode" cols="12" class="ma-0 pa-0">
                  <v-text-field ref="refUser" v-model="forgotten_password_code" name="password_code" placeholder=" " autocomplete="username" label="Код за верификация" prepend-icon="mdi-account" />
                </v-col>-->
                <v-col v-if="forgotenPasswordMode" cols="12" class="ma-0 pa-0">
                  <v-text-field
                    v-model="forgotten_password"
                    id="forgotten_password"
                    placeholder=" "
                    prepend-icon="mdi-lock"
                    name="password"
                    autocomplete="new-password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Нова парола"
                    :rules="global_rules.password"
                    @click:append="show1 = !show1"
                    @keydown="isCapsOn"
                  >
                    <CapsTooltip v-if="capsOn" slot="append-outer" />
                  </v-text-field>
                </v-col>
                <v-col v-if="forgotenPasswordMode" cols="12" class="ma-0 pa-0">
                  <v-text-field
                    v-model="forgotten_password_confirm"
                    id="forgotten_password_confirm"
                    placeholder=" "
                    prepend-icon="mdi-lock"
                    name="password"
                    autocomplete="new-password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Потвърди парола"
                    :rules="global_rules.password"
                    @click:append="show1 = !show1"
                    @keydown="isCapsOn"
                  >
                    <CapsTooltip v-if="capsOn" slot="append-outer" />
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions transition="scale-transition" class="my-0 py-0 pb-2">
            <v-container
              class="fill-height pt-0 my-0 py-0"
              fluid
              v-if="forgotenPasswordMode"
            >
              <v-row class="ma-1 pa-0">
                <v-col cols="12" class="my-0 py-0">
                  <v-btn
                    ref="refBtnLogin"
                    :color="Colors.IconColor"
                    class="white--text"
                    block
                    :disabled="!valid || wait_change_pass_timeout"
                    @click="changePass()"
                    autofocus
                    >Смяна на парола</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>

            <v-container v-else class="fill-height pt-0 my-0 py-0" fluid>
              <v-row class="ma-1 pa-0">
                <v-col cols="12" class="my-0 py-0">
                  <v-btn
                    ref="refBtnLogin"
                    :color="Colors.IconColor"
                    class="white--text"
                    block
                    :disabled="!valid || wait_login_timeout"
                    @click="login()"
                    autofocus
                    >Вход</v-btn
                  >
                </v-col>
                <!-- <v-col cols="12" lg="4" md="4" class="ma-0 pa-0">
                  <v-btn text small class="font-weight-black" :color="Colors.IconColor" @click="showForgottenPassDialog = true">Забравена парола</v-btn>
                </v-col>
                <v-col cols="12" lg="4" md="4" class="ma-0 pa-0">
                  <router-link to="/SignUp">
                    <v-btn text small class="font-weight-black" :color="Colors.IconColor">Регистрация</v-btn>
                  </router-link>
                </v-col>
                <v-col cols="12" lg="4" md="4" class="ma-0 pa-0">
                  <v-btn text small class="font-weight-black" :color="Colors.IconColor" @click="logWithDemo()">Демо</v-btn>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showForgottenPassDialog" width="500">
      <v-card>
        <v-card-title>
          <v-row align="center" justify="center">
            <span class="subtitle-1">Забравена парола</span>
          </v-row>
        </v-card-title>
        <v-card-actions class>
          <v-container class="fill-height pt-0 my-0 py-0" fluid>
            <v-row class="ma-0 pa-2">
              <v-text-field
                ref="refUser"
                v-model="username"
                name="username"
                placeholder=" "
                autocomplete="username"
                label="Потребител"
                prepend-icon="mdi-account"
                @keyup.enter="showForgottenPasswordDialog()"
              />
            </v-row>
            <v-row class="ma-0 pa-2">
              <v-btn
                ref="refBtnLogin"
                :color="Colors.IconColor"
                class="white--text"
                block
                :disabled="wait_login_timeout"
                @click="showForgottenPasswordDialog()"
                autofocus
                >Изпрати e-mail</v-btn
              >
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import HTTPSender from "@/js/HTTPSender";
import CapsTooltip from "../Controls/CapsTooltip";

export default {
  props: {
    propusername: String,
    proppassword: String,
    source: String,
  },
  components: {
    CapsTooltip,
  },
  data() {
    return {
      forgotten_password: "",
      forgotten_password_confirm: "",
      forgotten_password_code: "",
      forgotenPasswordMode: false,
      showForgottenPassDialog: false,
      capsOn: false,
      switch1: false,
      show1: false,
      valid: true,
      wait_login_timeout: false,
      wait_change_pass_timeout: false,
      username: this.propusername,
      password: this.proppassword,
      // emailRules: [
      //   v => !!v || "E-mail is required",
      //   v => /.+@.+/.test(v) || "E-mail must be valid"
      // ],
    };
  },
  methods: {
    async login() {
      if (
        this.$refs.loginForm &&
        this.$refs.loginForm.validate() &&
        !this.wait_login_timeout
      ) {
        this.wait_login_timeout = true;
        this.dialog
          .show("loader", null, { message: "Вход в системата..." })
          .then(() => {
            setTimeout(() => {
              var user = this.users.find(
                (u) =>
                  u.username == this.username && u.password == this.password
              );
              if (user) {
                this.$set(this.current_user, "username", user.username);
                this.$set(this.current_user, "devices", user.devices);
                this.$set(this.current_user, "isLogged", true);
                this.$router.push({ name: "main" }).then(() => {
                  this.dialog.close();
                });
              } else {
                this.wait_login_timeout = false;
                this.dialog.close();
                this.password = "";
                this.$toast.error("Грешен потребител или парола!");
              }
            }, 3000);
          });
      }
    },

    logWithDemo() {
      this.username = "Демо";
      this.password = "      ";
      this.$nextTick(() => {
        this.login();
      });
    },
    changePass() {
      if (
        this.$refs.loginForm &&
        this.$refs.loginForm.validate() &&
        !this.wait_change_pass_timeout
      ) {
        this.wait_change_pass_timeout = true;
        //TODO?
      }
    },
    showForgottenPasswordDialog() {
      if (!this.username) {
        this.dialogLoader.showError("Моля, въведете потребителско име!", {
          timeout: 5000,
          location: "center",
        });
      } else {
        this.wait_login_timeout = true;
        //TODO?
      }
    },
    isCapsOn(e) {
      this.capsOn = this.isCapsLockOn(e);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  updated() {
    this.dialogLoader.hide();
  },
  created() {},
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        if (this.users.length == 0) {
          HTTPSender.SendRequest(
            "GET",
            "",
            "https://demo.tremol.bg/",
            "users.json",
            null
          )
            .then((r) => {
              this.users = r;
            })
            .catch(() => {
              this.users = [
                {
                  username: "bim",
                  password: "12345",
                  devices: ["ZK960003"],
                },
                {
                  username: "demo",
                  password: "03333",
                  devices: ["ZK980002"],
                },
                {
                  username: "demo1",
                  password: "kifte",
                  devices: ["ZK960001"],
                },
                {
                  username: "demo2",
                  password: "kibapchi",
                  devices: ["ZK960002"],
                },
              ];
            });
        }
        this.$nextTick(() => {
          this.booleans.isLoading = false;
        });
      }
    };
    // this.$nextTick(() => {
    //   this.$refs.refPass.$el.focus();
    //   this.$nextTick(() => {
    //     this.$refs.refUser.$el.focus();
    //   });
    // });
    if (this.$route.query.user) {
      this.$nextTick(() => {
        this.username = this.$route.query.user;
      });
      //TODO?
    } else if (this.$route.query.psw_reset) {
      this.forgotenPasswordMode = true;
      this.forgotten_password_code = this.$route.query.psw_reset;
    } else if (this.$route.query.demo) {
      this.$nextTick(() => {
        this.logWithDemo();
      });
    }
  },
};
</script>

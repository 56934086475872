<template>
  <div>
    <v-app-bar app :height="80" style="background-color: white">
      <v-row align="center" justify="space-between">
        <img :width="60" height="auto" src="../assets/TRG03_22.jpg" />
        <v-select
          :disabled="isTerminalProcessing"
          v-model="Device"
          :items="Devices"
          dense
          single-line
          hide-details
          class="centered-input"
          style="width: 120px; max-width: 120px"
        ></v-select>
        <v-row self-align="center" justify="center">
          <img
            v-for="(car, idx) in cars"
            :key="idx"
            :height="80"
            class="mx-3"
            src="../assets/car-key2.png"
            style="cursor: pointer"
            :style="
              car != null && car.state != CarState.Gone
                ? 'visibility: hidden;'
                : ''
            "
            @click="car.arrive()"
          />
        </v-row>
      </v-row>
    </v-app-bar>
    <v-main app>
      <v-row
        justify="center"
        align="center"
        style="height: 100%"
        class="ma-0 pa-0"
      >
        <v-col class="ma-0 pa-0" style="justify-content: space-around">
          <v-row
            justify="center"
            align="center"
            v-for="(car, idx) in cars"
            :key="idx"
          >
            <label
              style="
                position: relative;
                z-index: 2222;
                left: 397px;
                top: -10px;
                font-weight: bold;
              "
              >{{ idx + 1 }}</label
            >
            <label
              v-if="car"
              style="
                position: relative;
                z-index: 2222;
                left: 335px;
                top: 60px;
                font-weight: bold;
                font-size: 14px;
                color: green;
              "
              >{{
                car.chargingAmount
                  .roundTo(2)
                  .toFixed(2)
                  .toString()
                  .padStart(5, "0") + " лв."
              }}</label
            >
            <img
              style="position: relative; left: 255px"
              :height="100"
              :class="
                car &&
                (car.state == CarState.Charging ||
                  car.state == CarState.StoppedCharging ||
                  car.state == CarState.Preauthing ||
                  car.state == CarState.Paying ||
                  car.state == CarState.Stopped)
                  ? 'station-cropped'
                  : ''
              "
              src="../assets/pro-135-reverse.png"
            />
            <CarView
              :ref="'car' + idx"
              :plugNum="idx + 1"
              style="position: relative; left: -118px; top: 0px"
              @click="carClick(car)"
              @carArrived="carArrived(car)"
              @carStartCharging="carStartCharging(car)"
              @carStoppedPreauthOrPay="carStoppedPreauthOrPay(car)"
              @carLeave="carLeave(car)"
              @amountChanged="amountChanged(car)"
            ></CarView>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
    <v-bottom-navigation app :height="80">
      <v-row align="center" justify="center">
        <v-select
          :disabled="isTerminalProcessing"
          v-model.number="CurrentMaxChargingAmount"
          :items="ChargingChoices"
          dense
          label="Сума за преавторизация"
          hide-details
          style="width: 140px; max-width: 140px"
          class="centered-input mr-4"
        ></v-select>
        <v-btn
          v-for="(car, idx) in cars"
          :key="idx"
          class="mx-4 px-0 rounded-btn elevation-5"
          :height="60"
          :width="10"
          :style="getButtonStyle(car)"
          :class="getButtonClass(car)"
          @click.prevent="buttonClick(car)"
        >
          <label style="font-size: 20px; cursor: inherit">{{ idx + 1 }} </label>
        </v-btn>
        <!-- </template> -->
      </v-row>
      <label style="position: absolute; bottom: 10px; right: 10px"
        >{{ version }}
      </label>
    </v-bottom-navigation>
  </div>
</template>

<script>
import CarView from "./CarView.vue";
import HTTPSender from "../js/HTTPSender.js";
import CarState from "../js/CarState";
import { version } from "../../package.json";

export default {
  name: "MainView",
  components: {
    CarView,
  },
  data() {
    return {
      TimeoutServerSpam: 987,
      TimeoutDisplay: 9000, //2222,
      HasToRefreshDisplay: true,
      DisplayTimeout: null,
      MainTimeout: null,
      // IsWithHardwareButtons: true,
      Device: null,
      IsChoosingMenu: false,
      Devices: [],
      CurrentMaxChargingAmount: 20,
      ChargingChoices: [2, 10, 20, 50],
      ChargeAmountForZero: 0.42,
      CarState: CarState,
      cars: [null, null],
      version: version,
    };
  },
  computed: {
    isTerminalProcessing() {
      return !!this.cars.find(
        (c) =>
          c != null &&
          (c.state == CarState.Preauthing || c.state == CarState.Paying)
      );
    },
  },
  methods: {
    main(to) {
      if (!to) to = this.TimeoutServerSpam;
      if (this.MainTimeout) clearTimeout(this.MainTimeout);
      this.MainTimeout = setTimeout(() => {
        //TODO remove
        // console.log(
        //   "MAIN: POS " +
        //     (this.isTerminalProcessing ? "1" : "0") +
        //     " MENU " +
        //     (this.IsChoosingMenu ? "1" : "0") +
        //     "   " +
        //     this.cars.map((c) => Object.keys(CarState)[c.state]).join("  ")
        // );
        if (this.isTerminalProcessing || this.IsChoosingMenu) {
          this.main();
          return;
        } else if (this.cars.find((c) => c.state == CarState.Stopped)) {
          this.IsChoosingMenu = true;
          this.buttonsLight().then(() => {
            this.selectMenu(true);
          });
        } else if (this.cars.find((c) => c.state == CarState.Charging)) {
          this.buttonsLight().then(() => {
            //TODO да не спамим integra-та?
            if (this.HasToRefreshDisplay) {
              this.HasToRefreshDisplay = false;
              if (this.DisplayTimeout) clearTimeout(this.DisplayTimeout);
              this.DisplayTimeout = setTimeout(() => {
                this.HasToRefreshDisplay = true;
              }, this.TimeoutDisplay);
              this.display().then(() => {
                this.main();
              });
            } else {
              this.main();
            }

            // this.display().then(() => {
            //   this.main();
            // });
          });
        } else {
          this.buttonsLight().then(() => {
            setTimeout(() => {
              this.display(
                "s1=ТРЕМОЛ управление&s2=на локални плащания&s5=ONLINE"
              ).then(() => {
                this.main(20000);
              });
            }, 10000);
          });
        }
      }, to);
    },
    amountChanged(car) {
      if (car.chargingAmount >= car.maxChargingAmount) {
        car.stopCharging();
        this.pay(car);
      } else {
        this.HasToRefreshDisplay = true;
        this.main();
      }
    },
    carArrived() {
      this.HasToRefreshDisplay = true;
      this.main();
    },
    carLeave() {
      this.HasToRefreshDisplay = true;
      this.main();
    },
    carStartCharging() {
      this.HasToRefreshDisplay = true;
      this.main();
    },
    carStoppedPreauthOrPay() {
      this.HasToRefreshDisplay = true;
      this.main();
    },
    buttonsLight() {
      return new Promise((resolve, reject) => {
        if (this.cars.find((c) => c.hasToRefreshButtons)) {
          this.cars.forEach((car) => {
            if (car) car.hasToRefreshButtons = false;
          });
          HTTPSender.SendGETreq(
            this.Device + "/ButtonsLight?key=1dlV21GJuIj0lSXsrR1EDYvQHQw7ZInXYGA2tcgxtDQEeUIvx4QHVtdirA7XWbWZ&val=" + this.getCarsButtonsString()
          )
            .done((res) => {
              if (res && !res.success) {
                this.log("Buttons light res not success");
                console.log(res);
              }
              resolve();
            })
            .fail((err) => {
              this.log("Buttons light fail");
              console.log(err);
              reject(err); //WE DONT CARE
            });
        } else {
          resolve();
        }
      }).catch(() => {
        //Server-a не работи?
      });
    },
    display(displayText) {
      return new Promise((resolve) => {
        // if (this.isTerminalProcessing) {
        //   resolve();
        //   return;
        // } else {
        if (!displayText) {
          displayText = "s1=Текущо състояние:&" + this.getCarsDisplayString();
        }
        HTTPSender.SendGETreq(this.Device + "/Display?key=1dlV21GJuIj0lSXsrR1EDYvQHQw7ZInXYGA2tcgxtDQEeUIvx4QHVtdirA7XWbWZ&to=10&" + displayText)
          .done((res) => {
            if (res && !res.success) {
              this.log("Display text res not success");
              console.log(res);
            }
            resolve();
          })
          .fail((err) => {
            this.log("Display text fail");
            console.log(err);
            resolve(err);
          });
        // }
      }).catch(() => {
        //Server-a не работи?
      });
    },
    async selectMenu(clearButtonsBuffer) {
      if (!this.IsChoosingMenu) return;
      if (clearButtonsBuffer) {
        await HTTPSender.SendGETreq(this.Device + "/ButtonsPressed?key=1dlV21GJuIj0lSXsrR1EDYvQHQw7ZInXYGA2tcgxtDQEeUIvx4QHVtdirA7XWbWZ")
          .done(() => {})
          .fail(() => {});
      }
      if (this.HasToRefreshDisplay) {
        this.HasToRefreshDisplay = false;
        if (this.DisplayTimeout) clearTimeout(this.DisplayTimeout);
        this.DisplayTimeout = setTimeout(() => {
          this.HasToRefreshDisplay = true;
        }, this.TimeoutDisplay);
        this.display(
          "to=10&s1=Избор на конектор:&" + this.getCarsDisplayString()
        ).then(() => {
          this.readButtons();
        });
      } else {
        setTimeout(() => {
          this.readButtons();
        }, this.TimeoutServerSpam);
      }
    },
    readButtons() {
      HTTPSender.SendGETreq(this.Device + "/ButtonsPressed?key=1dlV21GJuIj0lSXsrR1EDYvQHQw7ZInXYGA2tcgxtDQEeUIvx4QHVtdirA7XWbWZ")
        .done((res) => {
          this.IsChoosingMenu = !!this.cars.find(
            (c) => c.state == CarState.Stopped
          );
          // console.log(res);
          if (res && res.success && res.payload && res.payload.length) {
            var num = Number(res.payload[res.payload.length - 1]);
            var car = this.cars[num - 1];
            if (
              car &&
              car.state == CarState.Stopped &&
              car.chargingAmount == 0
            ) {
              this.buttonClick(car);
              // this.IsChoosingMenu = false;
            } else {
              if (num > 0) {
                this.$toast.error(
                  `Бутон ${num} не е валиден избор на конектор.`
                );
              }
              this.buttonsLight().then(() => {
                if (this.IsChoosingMenu) this.selectMenu();
                else this.main();
              });
            }
          } else {
            this.buttonsLight().then(() => {
              if (this.IsChoosingMenu) this.selectMenu();
              else this.main();
            });
          }
        })
        .fail((err) => {
          this.log("Read buttons fail");
          console.log(err);
          this.IsChoosingMenu = !!this.cars.find(
            (c) => c.state == CarState.Stopped
          );
          this.buttonsLight().then(() => {
            if (this.IsChoosingMenu) this.selectMenu();
            else this.main();
          });
        });
    },
    // selectMenu222() {
    //   HTTPSender.SendGETreq(
    //     this.Device +
    //       "/SelectMenu?to=5&s1=Избор на конектор:&" +
    //       this.getCarsDisplayString()
    //   )
    //     .done((res) => {
    //       // if (!this.IsChoosingMenu) return;
    //       this.IsChoosingMenu = !!this.cars.find(
    //         (c) => c.state == CarState.Stopped
    //       );
    //       // console.log(res);
    //       if (res && res.success && res.payload && res.payload.length) {
    //         var num = Number(res.payload[res.payload.length - 1]);
    //         var car = this.cars[num - 1];
    //         if (
    //           car &&
    //           car.state == CarState.Stopped &&
    //           car.chargingAmount == 0
    //         ) {
    //           this.buttonClick(car);
    //           // this.IsChoosingMenu = false;
    //         } else {
    //           if (num > 0) {
    //             this.$toast.error("Бутон " + num + " не е валиден избор.");
    //           }
    //           this.buttonsLight().then(() => {
    //             if (this.IsChoosingMenu) this.selectMenu();
    //             else this.main();
    //           });
    //         }
    //       } else {
    //         this.buttonsLight().then(() => {
    //           if (this.IsChoosingMenu) this.selectMenu();
    //           else this.main();
    //         });
    //       }
    //     })
    //     .fail((err) => {
    //       console.log("Selectmenu fail");
    //       console.log(err);
    //       this.buttonsLight().then(() => {
    //         if (this.cars.find((c) => c.state == CarState.Stopped))
    //           this.selectMenu();
    //       });
    //     });
    // },
    pay(car) {
      if (car.intervalPaying) clearInterval(car.intervalPaying);
      car.intervalPaying = setInterval(() => {
        if (!this.isTerminalProcessing) {
          car.pay();
          clearInterval(car.intervalPaying);
          if (car.chargingAmount <= 0) {
            car.chargingAmount = this.ChargeAmountForZero;
          }
          this.buttonsLight().then(() => {
            this.display().then(() => {
              HTTPSender.SendGETreq(
                this.Device +
                  `/CompletePreAuth?key=1dlV21GJuIj0lSXsrR1EDYvQHQw7ZInXYGA2tcgxtDQEeUIvx4QHVtdirA7XWbWZ&amount=${car.chargingAmount}&code=${car.preauthCode}`
              )
                .done((res) => {
                  car.end();
                  if (res && res.success) {
                    car.leave(); //TODO да не заминава колата ако е стигнала сумата?
                    this.main();
                    this.$toast.info(
                      `Успешна трансакция за ${car.chargingAmount
                        .roundTo(2)
                        .toFixed(2)
                        .toString()} лв.`
                    );
                  } else {
                    this.$toast.error(
                      `Грешка при опит за приключване на трансакция на конектор ${car.plugNumber}!\nИзвършване на повторен опит...`
                    );
                    this.pay(car);
                  }
                })
                .fail((err) => {
                  car.end();
                  this.log("Complete preauth fail");
                  console.log(err);
                  this.$toast.error(
                    `Грешка при опит за приключване на трансакция на конектор ${car.plugNumber}!\nИзвършване на повторен опит...`
                  );
                  this.pay(car);
                });
            });
          });
        }
      }, 234);
    },
    preauth(car) {
      this.IsChoosingMenu = false;
      HTTPSender.SendGETreq(
        this.Device +
          "/PreAuth?key=1dlV21GJuIj0lSXsrR1EDYvQHQw7ZInXYGA2tcgxtDQEeUIvx4QHVtdirA7XWbWZ&amount=" +
          this.CurrentMaxChargingAmount.toString()
      )
        .done((res) => {
          car.end();
          if (res && res.success) {
            car.preauthCode = res.payload.preAuthCode;
            car.startCharging(this.CurrentMaxChargingAmount);
          } else if (
            res &&
            res.payload &&
            res.payload.stageCode == 5 &&
            res.payload.stageStatusCode == 13
          ) {
            this.$toast.error(
              `Грешка при опит за преавторизация на конектор ${car.plugNumber}!\nОперацията е отказана от потребителя.`
            );
          } else if (res && res.err && res.err.message) {
            this.$toast.error(
              `Грешка при опит за преавторизация на конектор ${car.plugNumber}!\n${res.err.message}`
            );
          } else {
            this.$toast.error(
              `Грешка при опит за преавторизация на конектор ${car.plugNumber}!`
            );
          }
        })
        .fail((err) => {
          this.log("Preauth fail");
          console.log(err);
          car.end();
          this.$toast.error(
            `Грешка при опит за преавторизация на конектор ${car.plugNumber}! Няма връзка с Tremol API!`
          );
        });
    },
    getCarsDisplayString() {
      var s = [];
      for (var i = 0; i < this.cars.length; i++) {
        s.push("s" + (i + 2) + "=" + (i + 1) + ") " + this.cars[i].stateString);
      }
      return s.join("&");
    },
    getCarsButtonsString() {
      var s = [];
      for (var i = 0; i < this.cars.length; i++) {
        if (this.cars[i].stateButton) {
          s.push(i + 1 + "-" + this.cars[i].stateButton);
        }
      }
      return s.join(";");
    },
    buttonClick(car) {
      this.IsChoosingMenu = false;
      if (!car || this.isTerminalProcessing) {
        return;
      } else if (car.state == CarState.Stopped && car.chargingAmount == 0) {
        car.preauth();
        // this.$nextTick(() => {
        this.buttonsLight().then(() => {
          this.display().then(() => {
            this.preauth(car);
          });
        });
        // });
      }
    },
    carClick(car) {
      if (
        !car ||
        car.state == CarState.Preauthing ||
        car.state == CarState.Paying
      ) {
        return;
      } else if (car.state == CarState.Charging) {
        car.stopCharging();
        this.pay(car);
      } else if (car.state == CarState.Stopped) {
        car.leave();
      }
    },
    getButtonStyle(car) {
      var style = "";
      if (!car || car.state == CarState.Gone) return "cursor: not-allowed;";
      else if (
        car.state == CarState.Charging ||
        car.state == CarState.Preauthing ||
        car.state == CarState.Paying ||
        car.state == CarState.StoppedCharging
      ) {
        style += "border: 12px solid lightgreen;";
      } else if (car.state == CarState.Stopped) {
        style += "border: 12px solid #246fb3;";
      }
      if (this.isTerminalProcessing) {
        style += "cursor: wait;";
      } else if (car.state == CarState.Charging) {
        style += "cursor: not-allowed";
      }
      return style;
    },
    getButtonClass(car) {
      if (!car) return "";
      switch (car.state) {
        case CarState.Charging:
          return "blinkingBorder";
        default:
          return "";
      }
    },
    log(o) {
      console.log(new Date().toString() + "  " + o);
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.current_user.isLogged) {
        this.Devices = this.current_user.devices;
        this.Device = this.Devices[0];
        for (var i = 0; i < this.cars.length; i++) {
          this.$set(this.cars, i, this.$refs["car" + i][0]);
        }
        this.main();
      } else {
        this.$router.push({ name: "login" });
      }
    });
  },
  destroyed() {
    if (this.MainTimeout) clearTimeout(this.MainTimeout);
  },
};
</script>
<style>
.station-cropped {
  object-fit: cover;
  clip-path: polygon(
    59px 0px,
    100px 0px,
    100px 100px,
    0px 100px,
    0px 50px,
    59px 50px
  );
  /* clip: rect(0px, 49px, 120px, 0px); */
}

div.btn__content {
  padding: 0;
}

div.card__actions .btn {
  min-width: 0;
}

.rounded-btn {
  min-width: 0;
  border-radius: 30px !important;
  max-width: 20px !important;
}

.blinkingBorder {
  animation: blinkingBorder 1s infinite;
}

@-o-keyframes blinkingBorder {
  0% {
    border: 12px solid lightgreen;
  }
  50% {
    border: 12px solid lightgreen;
  }
  51% {
    border: none;
  }
  100% {
    border: none;
  }
}
@-moz-keyframes blinkingBorder {
  0% {
    border: 12px solid lightgreen;
  }
  50% {
    border: 12px solid lightgreen;
  }
  51% {
    border: none;
  }
  100% {
    border: none;
  }
}
@-webkit-keyframes blinkingBorder {
  0% {
    border: 12px solid lightgreen;
  }
  50% {
    border: 12px solid lightgreen;
  }
  51% {
    border: none;
  }
  100% {
    border: none;
  }
}
@keyframes blinkingBorder {
  0% {
    border: 12px solid lightgreen;
  }
  50% {
    border: 12px solid lightgreen;
  }
  51% {
    border: none;
  }
  100% {
    border: none;
  }
}

/*
::v-deep .v-btn {
  padding-left: 22px;
  padding-right: 22px;
}

.rounded-btn > span {
  max-width: 26px;
  min-width: 0;
}
 */
</style>
<template>
  <v-app app id="app" style="max-height: 100svh; height: 100svh">
    <dialog-loader id="dialogLoader" ref="refDialogLoader" />
    <highlighter id="highlighter" ref="refHighlighter" />
    <v-dialog
      style="z-index: 999999"
      v-model="dialog.modal_loader"
      v-if="dialog.modal_loader"
      width="unset"
      :min-height="200"
      class="ma-0 pa-0"
      persistent
    >
      <loading-view />
    </v-dialog>

    <router-view class="ma-0 pa-0 routercontainer" />
  </v-app>
</template>

<script>
import DialogLoader from "./components/Controls/LoaderView.vue";
import Highlighter from "./components/Controls/HighLighter";
import LoadingView from "./components/Controls/LoadingView";

export default {
  name: "App",
  components: {
    DialogLoader,
    Highlighter,
    LoadingView,
  },
};
</script>

<style>
html {
  overflow: hidden;
}

body {
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.routercontainer {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.centered-input,
.centered-input label,
.centered-input input,
.centered-input v-input__control v-input__slot v-text-field__slot label {
  text-align: center;
}

.centered-input .v-select__selection {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.centered-input input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.centered-input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.centered-input input::-webkit-outer-spin-button,
.centered-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
  margin: 0;
}

.centered-input::-webkit-outer-spin-button,
.centered-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
</style>

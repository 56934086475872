import Vue from 'vue'
import App from './App.vue'
import "babel-polyfill"
import Vuetify from "vuetify"
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import global_dialog from "./g_dialog.js";
import Background from "./components/LoginPage/BackgroundView.vue";
import router from "./routes";

Vue.config.productionTip = false
Vue.use(Vuetify);

Vue.component("t-background", Background);

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,

  position: "bottom-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

var global_dial = new global_dialog();
export let g_Dialog = global_dial;

var g_dialogLoader = {
  show: null,
  hide: null,
  hideSnackbar: null,
  showSnackbar: null,
  showError: null,
  showInfo: null,
};
var g_highlighter = {
  highlight: null,
  stop: null,
  visible: false,
  interval: null,
};
var g_userSettings = {
  denseGrid: true,
  darkMode: false,
  hideOldModels: false,
  showTooltips: true,
  showFlyingHelp: true,
  startPage: "",
};
var g_booleans = {
  bannerVisible: false,
  isLoading: true,
};

var g_currentUser = {
  isLogged: false,
  username: "",
  password: "",
  devices: []
};

Vue.mixin({
  data() {
    return {
      current_user: g_currentUser,
      users: [],
      highlighter: g_highlighter,
      dialogLoader: g_dialogLoader,
      dialog: global_dial,
      booleans: g_booleans,
      userSettings: g_userSettings,
      global_rules: {
        required: [(v) => !!v || "Задължително поле", (v) => (v && v.toString().length >= 2) || "Невалидна стойност"],
        money: [(v) => (!!v && Number(v) == v && Number(v).roundTo(2) == v && v.toString().length < 9) || "Невалидна стойност"],
        number9digits: [(v) => !!v || "Задължително поле", (v) => (v && v.toString().length < 9) || "Невалидна стойност"],
        validEIK: [(v) => (v && ((v.length == 9 && this.isValidEIK(v)) || v.length >= 10 && v.length <= 13)) || "Невалиден ЕИК"],
        username: [(v) => !!v || "Моля, въведете потребителско име", (v) => (v && v.length >= 3 && v.length < 30) || "Потребителското име трябва да бъде минимум 3 и максимум 30 символа"],
        password: [(v) => !!v || "Моля, въведете парола", (v) => (v && v.length >= 5 && v.length < 30) || "Паролата трябва да бъде минимум 5 и максимум 30 символа"],
        email: [(v) => !!v || "Задължително поле", (v) => /.+@.+/.test(v) || "Невалидна електронна поща (e-mail) "]
      },
    }
  },
  computed: {
    Colors() {
      return {
        IconColorHex: this.isInDarkMode ? "#1685c9" : "#1685c9",
        IconColor: this.isInDarkMode ? "rgb(22, 133, 201)" : "rgb(0, 46, 110)",
        InactiveIconColor: this.isInDarkMode ? "rgba(22, 133, 201, 0.3)" : "rgba(0, 46, 110, 0.3)",
        ActiveItemBackground: this.isInDarkMode ? "#4f4f4f" : "rgb(150, 196, 250)",
        MenuColor: this.isInDarkMode ? "" : "#d6ecfa", //rgb(213, 236, 244)",
        MenuTextColor: this.isInDarkMode ? "" : "rgb(0, 46, 110)",
        //  MenuColor: this.isInDarkMode ? "" : "rgb(92, 182, 243)",
        //  MenuTextColor: this.isInDarkMode ? "" : "rgb(245, 255, 255)",
        TransparentColor: "rgba(0, 0, 0, .05), rgba(0, 0, 0, .05)",
        SimpleBackground: this.isInDarkMode ? "#333" : "#FFF",
      };
    },
    isInDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    openWebSite(site) {
      window.open(site, "_blank");
    },
    openTremolSite(deviceModel) {
      if (deviceModel) {
        this.openWebSite("https://tremol.bg/bg/product/" + deviceModel.replaceAll(" ", "-"));
      } else {
        this.openWebSite("https://tremol.bg/bg/");
      }
    },
    isCapsLockOn(e) {
      // console.log(e);
      let caps = e.getModifierState && e.getModifierState("CapsLock");
      if (caps == true) return true;
      else return false;
    },
  }
});


if (!Math.randomFromInterval) {
  Math.randomFromInterval = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
}

if (!Number.prototype.roundTo) {
  Number.prototype.roundTo = function (digits) {
    //много тъп краден код...
    var negative = false;
    var n = this;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
      n = (n * -1).toFixed(digits);
    }
    return Number(n);
  };
}

export const Const = Object.freeze({
  Messages: {
    //None: { Rank: 0, text: "", Color: ""},
    Other: { Rank: 3, text: "Друг вид", Color: "#969CC9" },
    Info: { Rank: 1, text: "Информация", Color: "#48BBFB" },
    Important: { Rank: 5, text: "Важно", Color: "#11994c" },
    Warning: { Rank: 7, text: "Предупреждение", Color: "#FFBB00" },
    Urgent: { Rank: 9, text: "Спешно", Color: "#FF553B" },
  },
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon color="info" v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "mdi-caps-lock",
    },
    tooltipText: {
      type: String,
      default: "Caps Lock е пуснат!",
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div v-if="snackbarVisible || dialogVisible" class="ma-0 pa-0">
    <v-dialog class="roundedcorners ma-3 pa-3" :width="options.width" :height="options.height" :min-width="isWithSmallWidth ? options.minWidthMobile : options.minWidth" :min-height="options.minHeight" v-bind:style="{ zIndex: options.zIndex }">
      <v-card :color="options.color" :width="options.width" :height="options.height" :min-width="options.minWidth" :min-height="options.minHeight" class="ma-0 pa-2" dark align="center" justify="center">
        <v-card-text class="ma-0 pa-4">
          <span>{{ message }}</span>
          <v-spacer></v-spacer>
        </v-card-text>
        <v-progress-circular v-if="circular" indeterminate size="40" width="8" class="ma-0 pa-2"></v-progress-circular>
        <v-progress-linear v-else indeterminate striped color="white" class="roundedcorners ma-0 pa-2"></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-snackbar
      class="ma-0 pa-5"
      v-model="snackbarVisible"
      :color="snackbar.color"
      :centered="snackbar.location === 'center'"
      :left="snackbar.location === 'left'"
      :right="snackbar.location === 'right'"
      :top="snackbar.location === 'top'"
      :bottom="snackbar.location === 'bottom'"
      :multi-line="snackbar.mode === 'multi-line'"
      :vertical="snackbar.mode === 'vertical'"
      :timeout="snackbar.timeout"
      :width="options.width"
      :min-width="isWithSmallWidth ? options.minWidthMobile : options.minWidth"
    >
      <v-row class="ma-0 pa-0 box-container-horizontal" justify="space-between" align="center">
        <div class="ma-0 pa-0 row-content" style="height: 100; max-width: 100%; overflow: hidden">
          <v-row class="ma-0 pa-0" style="width: 100%" v-for="(msg, idx) in snackbar.message.split('\n')" :key="idx">
            <a v-if="msg.startsWith('tel:')" class="ma-0 pa-2" :style="'width: 100%; color: ' + snackbar.colorFont" :href="msg" target="_blank">{{ msg.replace("tel:", "") }}</a>
            <span v-else class="ma-0 pa-2" :style="'max-width: 100%; width: 100%; color: ' + snackbar.colorFont">{{ msg }}</span>
          </v-row>
        </div>
        <v-btn class="ma-0 pa-2 row-header" align="end" icon :color="snackbar.close.color" @click="hideSnackbar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      circular: false,
      message: null,
      options: {
        color: "",
        width: "unset",
        height: "unset",
        minWidth: 320,
        minWidthMobile: 320,
        minHeight: 100,
        zIndex: 20000,
      },
      snackbarVisible: false,
      snackbar: {
        enabled: false,
        color: "grey lighten-4",
        colorFont: "#000",
        mode: "multi-line",
        timeout: 5000,
        message: "Success!",
        callback: null,
        location: "center",
        close: {
          text: "Close",
          color: "black",
        },
      },
    };
  },
  methods: {
    show: function (message, options) {
      this.message = message;
      this.options = Object.assign(this.options, options);
      this.dialogVisible = true;
    },
    hide: function () {
      this.dialogVisible = false;
    },
    hideSnackbar: function () {
      if (this.snackbar.callback) {
        this.snackbar.callback();
      }
      this.snackbarVisible = false;
    },
    showSnackbar: function (message, snackbarOptions) {
      if (message) {
        this.snackbar.message = message;
      }
      this.snackbar = Object.assign(this.snackbar, snackbarOptions);
      this.snackbar.enabled = true;
      this.snackbarVisible = true;
    },
    showError: function (message, snackbarOptions) {
      this.snackbar.location = "center";
      this.showSnackbar(
        message,
        Object.assign(
          {
            color: "red lighten-2",
            timeout: 5000,
          },
          snackbarOptions,
        ),
      );
    },
    showInfo: function (message, snackbarOptions) {
      this.snackbar.location = "center";
      this.showSnackbar(
        message,
        Object.assign(
          {
            color: this.isInDarkMode ? "grey darken-3" : "grey lighten-4",
            colorFont: this.isInDarkMode ? "#FFF" : "#000",
            timeout: 2000,
          },
          snackbarOptions,
        ),
      );
    },
  },
  mounted() {
    this.dialogLoader.show = this.show;
    this.dialogLoader.hide = this.hide;
    this.dialogLoader.showSnackbar = this.showSnackbar;
    this.dialogLoader.hideSnackbar = this.hideSnackbar;
    this.dialogLoader.showError = this.showError;
    this.dialogLoader.showInfo = this.showInfo;
  },
};
</script>

<style scoped>
.roundedcorners {
  border-radius: 5px;
}

div >>> v-snack__content {
  width: unset;
}
</style>
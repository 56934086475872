<template>
  <div :class="getClass" style="display: block; width: 250px; height: 150px">
    <img
      @click="
        if (state == CarState.Stopped || state == CarState.Charging) click();
      "
      alt="AlfaRomeo"
      width="250px"
      height="150px"
      src="../assets/tesla5.webp"
      :style="
        state == CarState.Stopped || state == CarState.Charging
          ? 'cursor: pointer'
          : ''
      "
    />
    <img
      width="80px"
      height="auto"
      :class="getPlugClass"
      style="
        position: relative;
        right: -130px;
        top: -111px;
        -o-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      "
      src="../assets/type2-plug-male-clear.png"
    />
  </div>
</template>

<script>
import CarState from "../js/CarState";

export default {
  props: {
    plugNum: Number,
  },
  data() {
    return {
      state: CarState.Gone,
      stateString: "Свободен",
      stateButton: "",
      preauthCode: "",
      hasToRefreshButtons: true,
      CarState: CarState,
      plugNumber: this.plugNum,
      intervalPaying: null,
      intervalCharging: null,
      chargingAmount: 0,
      maxChargingAmount: 0.2,
      chargingTick: 0.5,
      carNum: Math.randomFromInterval(1, 2),
    };
  },
  computed: {
    getClass() {
      switch (this.state) {
        case CarState.Gone:
          return "hidden-car";
        case CarState.Arriving:
          return "arriving-car";
        case CarState.Leaving:
          return "leaving-car";
        default:
          return "";
      }
    },
    getPlugClass() {
      switch (this.state) {
        case CarState.Gone:
        case CarState.Arriving:
        case CarState.Leaving:
          return "hidden-car";
        case CarState.Charging:
          return "blinking";
        default:
          return "";
      }
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
    arrive() {
      this.state = CarState.Arriving;
      this.chargingAmount = 0;
      this.preauthCode = "";
      setTimeout(() => {
        this.setState(CarState.Stopped, "Свързан", "Blue", "carArrived");
      }, 1490);
    },
    leave() {
      if (this.intervalCharging) clearInterval(this.intervalCharging);
      this.setState(CarState.Leaving, "Свободен", "", "carLeave");
      setTimeout(() => {
        this.state = CarState.Gone;
      }, 1400);
    },
    startCharging(maxAmount) {
      this.setState(
        CarState.Charging,
        "Стартира заряд",
        "Green-Blink",
        "carStartCharging"
      );

      this.maxChargingAmount = maxAmount;

      this.intervalCharging = setInterval(() => {
        if (this.chargingAmount >= this.maxChargingAmount) {
          this.stopCharging();
          // clearInterval(this.intervalCharging);
        }
        this.chargingAmount = (this.chargingAmount + this.chargingTick).roundTo(
          2
        );
        this.stateString =
          (this.chargingAmount * 1.2).roundTo(2).toFixed(2).toString() +
          "KWh " +
          this.chargingAmount.roundTo(2).toFixed(2).toString() +
          " лв.";
        this.$emit("amountChanged", this.chargingAmount);
      }, 5000);
    },
    stopCharging() {
      if (this.intervalCharging) clearInterval(this.intervalCharging);
      this.setState(
        CarState.StoppedCharging,
        "Приключва заряд",
        "Green",
        "carStoppedCharging"
      );
    },
    preauth() {
      this.setState(
        CarState.Preauthing,
        "Резервира сума",
        "Green",
        "carStartedPreauth"
      );
    },
    pay() {
      this.setState(
        CarState.Paying,
        "Извършва плащане",
        "Green",
        "carStartedPaying"
      );
    },
    end() {
      this.setState(
        CarState.Stopped,
        "Свързан",
        "Blue",
        "carStoppedPreauthOrPay"
      );
    },
    setState(state, txt, btn, emitFn) {
      this.state = state;
      this.stateString = txt;
      this.stateButton = btn;
      this.hasToRefreshButtons = true;
      this.$emit(emitFn, this);
    },
  },
  watch: {},
};
</script>

<style>
.hidden-car {
  visibility: hidden;
}

.blinking {
  background-color: lightgreen;
  border-radius: 30px;
  animation: blinking 1s infinite;
}

.arriving-car {
  -webkit-animation: linear;
  -webkit-animation-name: arrive;
  -webkit-animation-duration: 1.5s;

  -moz-animation: linear;
  -moz-animation-name: arrive;
  -moz-animation-duration: 1.5s;

  -o-animation: linear;
  -o-animation-name: arrive;
  -o-animation-duration: 1.5s;

  animation: linear;
  animation-name: arrive;
  animation-duration: 1.5s;
}

@-o-keyframes arrive {
  0% {
    transform: translateX(1500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-moz-keyframes arrive {
  0% {
    transform: translateX(1500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@-webkit-keyframes arrive {
  0% {
    transform: translateX(1500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes arrive {
  0% {
    transform: translateX(1500px);
  }
  100% {
    transform: translateX(0px);
  }
}

.leaving-car {
  -webkit-animation: linear;
  -webkit-animation-name: leave;
  -webkit-animation-duration: 1.5s;

  -moz-animation: linear;
  -moz-animation-name: leave;
  -moz-animation-duration: 1.5s;

  -o-animation: linear;
  -o-animation-name: leave;
  -o-animation-duration: 1.5s;

  animation: linear;
  animation-name: leave;
  animation-duration: 1.5s;
}

@-o-keyframes leave {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-1500px);
  }
}
@-moz-keyframes leave {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-1500px);
  }
}
@-webkit-keyframes leave {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-1500px);
  }
}
@keyframes leave {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-1500px);
  }
}

@-o-keyframes blinking {
  0% {
    background-color: lightgreen;
  }
  /* 50% {
    background-color: rgba(144, 238, 144, 0.5);
  } */
  100% {
    background-color: transparent;
  }
}
@-moz-keyframes blinking {
  0% {
    background-color: lightgreen;
  }
  /* 50% {
    background-color: rgba(144, 238, 144, 0.5);
  } */
  100% {
    background-color: transparent;
  }
}
@-webkit-keyframes blinking {
  0% {
    background-color: lightgreen;
  }
  /* 50% {
    background-color: rgba(144, 238, 144, 0.5);
  } */
  100% {
    background-color: transparent;
  }
}
@keyframes blinking {
  0% {
    background-color: lightgreen;
  }
  /* 50% {
    background-color: rgba(144, 238, 144, 0.5);
  } */
  100% {
    background-color: transparent;
  }
}
</style>